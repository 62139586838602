import { useEffect } from "react";

import { fetchBids } from "./apis";

export const useRenderSlot = (slot: googletag.Slot | null, refreshCount?: number) => {
  useEffect(() => {
    if (!slot) return;
    const isValidApsAdSlot = (slot: googletag.Slot) => slot.getSizes().some(s => s !== "fluid");

    let hasCancel = false;
    if (isValidApsAdSlot(slot)) {
      const run = async () => {
        const { result } = fetchBids([slot]);
        await result;
        if (hasCancel) return;

        googletag.cmd.push(() => {
          googletag.pubads().refresh([slot]);
        });
      };
      void run();
    } else {
      googletag.cmd.push(() => {
        googletag.pubads().refresh([slot]);
      });
    }

    return () => {
      hasCancel = true;
    };
  }, [slot, refreshCount]);
};
