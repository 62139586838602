import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import type { WithStickyStackedHeightProps } from "~/lib/styles";

import { AdSlotLabelHeight } from "./consts";
import { Slot } from "./slot";

export type StyledProps = {
  $breakpoint?: string;
  $currentPosition?: string;
  $minContainerBlockSize?: number;
  $minContainerInlineSize?: number;
  $minContainerSize?: {
    $blockSize?: number;
    $inlineSize?: number;
  };
  $withAutoContainerSize?: boolean;
  $withBackground?: boolean;
  $withLabel?: boolean;
  $withSticky?: {
    $blockSize?: number;
    $inlineSize?: number;
    $stickyLabel?: boolean;
  };
} & WithStickyStackedHeightProps;

export const Label = styled.div`
  inline-size: 100%;

  color: #bbbbbb;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: ${AdSlotLabelHeight}px;
  text-align: center;
`;

export const AdSlotPlaceholder = styled.div``;

export const StyledSlot = styled(Slot)`
  inline-size: 100%;
  block-size: 100%;
`;

export const Container = styled.div<StyledProps>`
  flex-flow: column;
  justify-content: ${props => (props.$withLabel ? "flex-start" : "center")};
  align-items: center;

  inline-size: 100%;
  block-size: 100%;

  ${props =>
    props.$withBackground &&
    css`
      background-color: #f6f6f6;
      padding: 0 1px 1px;
    `}

  ${props => {
    if (props.$withSticky) {
      return props.$withSticky.$stickyLabel
        ? css`
            position: sticky;
            top: ${props.$currentPosition === "sticky" ? props.$stickyStackedHeight : 0}px;
            block-size: fit-content;
          `
        : css`
            min-block-size: ${props.$withSticky.$blockSize}px;

            ${AdSlotPlaceholder}, ${StyledSlot} {
              position: sticky;
              top: ${props.$currentPosition === "sticky" ? props.$stickyStackedHeight : 0}px;
            }
          `;
    }
  }}

  ${props =>
    props.$breakpoint
      ? css`
          display: none;

          ${props.$breakpoint} {
            display: flex;
          }
        `
      : css`
          display: flex;
        `}

  ${Label} {
    display: ${props => (props.$withLabel ? "block" : "none")};
  }

  ${AdSlotPlaceholder}, ${StyledSlot} {
    display: flex;
    justify-content: center;
    align-items: center;

    inline-size: ${props => (props.$withAutoContainerSize ? "100%" : "auto")};
    block-size: ${props => (props.$withAutoContainerSize ? "100%" : "auto")};
    min-inline-size: ${props =>
      props.$minContainerSize?.$inlineSize ? `${props.$minContainerSize.$inlineSize}px` : "unset"};
    min-block-size: ${props =>
      props.$minContainerSize?.$blockSize ? `${props.$minContainerSize.$blockSize}px` : "unset"};
  }
`;
