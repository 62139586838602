import { atom } from "jotai";

export type OnePlusX = {
  audienceId?: {
    part0: string;
    part1: string;
  };
  profile?: {
    audienceSegmentIds: string[];
    topicIds: string[];
  };
};

export const onePlusXAtom = atom<OnePlusX>({});
