import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import { config } from "~/data";
import { accountAtom } from "~/lib/account";
import { useScmpWidgetConfig } from "~/lib/app/hooks";
import { useMyNews, useParsedCookie } from "~/lib/hooks";

import type { AdUnit } from "./types";

export const useUserRoleTarget = () => {
  enum REGISTER_TYPE {
    APPLE = "apple",
    EMAIL = "email",
    FACEBOOK = "facebook",
    GOOGLE = "google",
    GOOGLE_GSUITE = "google:gsuite",
    GOOGLE_YOLO = "google:yolo",
    PIANO_EMAIL = "piano:email",
    PIANO_SWG = "piano:swg",
  }
  enum USER_LEVEL {
    SCMP_EXPERT = "SCMP Expert",
    SCMP_STAFF = "SCMP Staff",
  }

  const { isLoggedIn, user } = useAtomValue(accountAtom);
  const { value: scmpSubscriberCookie } = useParsedCookie("scmp_subscriber", raw => raw, {
    initializeWithValue: true,
  });

  if (!isLoggedIn) return { sub: "user", subType: "1" };
  if (user?.lvl?.includes(USER_LEVEL.SCMP_STAFF)) return { sub: "paid", subType: "13" };
  if (user?.lvl?.includes(USER_LEVEL.SCMP_EXPERT)) return { sub: "paid", subType: "12" };

  // Use cookie here is because don't want to delay the ad rendering
  const isScmpSubscriber = new Set(["active", "true"]).has(scmpSubscriberCookie ?? "");
  const sub = isScmpSubscriber ? "paid" : "user";
  let subType = isScmpSubscriber ? "11" : "6";

  const map: Record<string, string> = {
    [REGISTER_TYPE.EMAIL]: isScmpSubscriber ? "9" : "4",
    [REGISTER_TYPE.FACEBOOK]: isScmpSubscriber ? "7" : "2",
    [REGISTER_TYPE.GOOGLE]: isScmpSubscriber ? "8" : "3",
    [REGISTER_TYPE.GOOGLE_YOLO]: isScmpSubscriber ? "10" : "5",
  };
  if (user?.source && map[user.source]) subType = map[user.source];

  return { sub, subType };
};

export const useAdProfile = () => {
  const { scmpWidgetConfigState } = useScmpWidgetConfig();
  const { hasEnoughFollowedItems, isVisitedMyNewsPage } = useMyNews();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const [adProfile, setAdProfile] = useState<string[]>([]);

  useEffect(() => {
    if (
      !scmpWidgetConfigState.result?.my_news.is_enabled_in_ad_profile ||
      !isVisitedMyNewsPage ||
      hasEnoughFollowedItems ||
      !isLoggedIn
    )
      return;
    // Need to setup the ad profile when
    // 1. Landing popup has been shown
    // 2. Don't have enough follow items to setup mynews page
    // 3. Drupal config is on
    // 4. User is logged in
    setAdProfile(["mynews"]);
  }, [
    hasEnoughFollowedItems,
    isLoggedIn,
    isVisitedMyNewsPage,
    scmpWidgetConfigState.result?.my_news.is_enabled_in_ad_profile,
  ]);

  return {
    adProfile,
  };
};

export const useAdUnitPath = (adUnit: AdUnit, zone?: string | null) => {
  const { adUnitPrefix, networkId } = config.advertisement.googlePublisherTag;
  return `/${networkId}/${adUnitPrefix}/${zone ?? "default"}/${adUnit}`;
};
