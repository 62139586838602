import axios from "axios";
import http from "http";
import https from "https";

import { config } from "~/data";

import type { ViewabilityEventPayload } from "./types";

const instance = axios.create({
  baseURL: config.advertisement.viewabilityService.url,
  headers: { apiKey: config.advertisement.viewabilityService.apiKey },
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
});

export async function trackViewabilityEvents(items: ViewabilityEventPayload[]) {
  if (items.length === 0) return;
  const response = await instance.post("", items);
  return response;
}
